import React from "react";
import Grid from "components/ui/Grid/Grid";

const AuditoriaProcesos = () => {
	return (
		<Grid col justify="center" grow>
			En desarrollo
		</Grid>
	);
};

export default AuditoriaProcesos;
