//Afiliados - Menu
export const MODULO_SELECCIONAR = "MODULO_SELECCIONAR";
export const AFILIADO_SELECCIONAR = "AFILIADO_SELECCIONAR";
export const EMPRESA_SELECCIONAR = "EMPRESA_SELECCIONAR";
export const LIQUIDACION_PROCESAR_SELECCIONAR = "LIQUIDACION_PROCESAR_SELECCIONAR";
export const MODULO_EJECUTARACCION = "MODULO_EJECUTARACCION";
export const SET_NAV_FUNCTION = "SET_NAV_FUNCTION";


//Usuario Logueado
export const USUARIO_LOGUEADO = "USUARIO_LOGUEADO"

//Administracion - Menu
